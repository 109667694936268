// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../template/RenderPage';

// TempData
import BetaLoginData from '../mocks/pages/PageData/betaLogin';

// Class
const BetaLogin = () => <div>{renderPage(BetaLoginData)}</div>;

export default BetaLogin;
