// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const BetaLoginData = {
  pageName: 'BetaLogin',
  pageTemplate: 'Template2',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    bannerImage: null,
    bannerContent:
      '<h1>Få overblik over din pension</h1><p>Savner du overblik over din pension - hvad indeholder ordningen egentlig, hvor stor er min opsparing, hvor meget indbetaler jeg og sparer jeg nok op? Her er tre indgange til at få overblik.</p>',
    desktopTools: null,
  },
  pageContent: [
    {
      componentName: 'Beta',
      componentData: {
        id: 1,
        header: 'Log ind på det nye digitale univers med NemID',
        nemIdBtn: 'Gammelt log ind',
        getAppBtn: 'Få appen her',
        nemidBtnText: 'Log ind med NemID',

        textBlock:
          '<center><h3>Generelt forbehold i forhold til brug af det nye login univers og appen.</h3></center><br/>'
          + '<p>Det nye login univers og appen gennemgås løbende for at sikre, at alle informationer, funktioner og selvbetjeningsværktøjer er opdaterede, korrekte og præcise.<br/><br/>Der er dog ingen garanti for, at der ikke kan være regne-, tastefejl, mangler, undladelser eller på anden måde være sket ændringer, som gør, at informationerne i det nye login univers og appen er fejlagtige, forældede eller misvisende. Derfor fraskrives der ethvert ansvar for sådanne fejl og mangler og for eventuelle tab forbundet med brug af de generelle informationer og funktioner i det nye login univers og appen. Der tages også forbehold for manuelle fejl, herunder regne-, tastefejl ved svar via de elektroniske kanaler fx beskeder i postkassen.<br/><br/>Ved enhver tvist om fortolkningen af informationer, materiale mv. i det nye login unvers og appen eller digitale svar på henvendelser mv. fx om ret til og størrelse på ydelser og forsikringer, har det til enhver tid gældende aftalegrundlag for den enkelte pensionsordning forrang. Med det gældende aftalegrundlag mener vi den til enhver tid gældende individuelle pensions- og forsikringsoversigt og de til enhver tid gældende vilkår for pensionsordningen.</p>',

        mobileText:
          '<center><h1 style="font-size:35px">Vil du prøve den nye test app? - skriv til os</h1><p class="btn-bottom-center"><a href="beta@sampension.dk?subject=Adgang til test app" class="linkBtn" style="width:95%;margin: 0 auto -20px auto;">Skriv til beta@sampension.dk</a></p></center>',

        modalSignUp: '<h2>Skriv mig op</h2><h3>Få appen her</h3>',
        modalFinish:
          '<h2>Tak for din henvendelse</h2><h3>Du vil snart modtage en vejledning,<br/>så du kan installere vores app.</h3>',
      },
    },
  ],
};

// Exports
export default BetaLoginData;
